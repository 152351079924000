declare var dialogPolyfill;
function setupAnimation() {
	let animateVisible = function () {
		document.querySelectorAll(".bg-section").forEach(section => {
			let bcr = section.getBoundingClientRect();
			if (bcr.top <= (window.innerHeight / 2)) {
				section.querySelectorAll(".bg--animate_in:not(.bg--running)").forEach((animatedElement: HTMLElement, index) => {
					animatedElement.style.animationDelay = `${index * 0.25}s`;
					animatedElement.classList.add("bg--running");
				});
			}
		});
	};
	window.addEventListener("scroll", animateVisible);
	animateVisible();
}
function setupCareersDialog() {
	let anchor = document.querySelector<HTMLAnchorElement>("a[href=\"#careers\"]");
	let dialog = document.querySelector<HTMLDialogElement>("dialog#careers-dialog");
	let close = document.querySelector<HTMLButtonElement>("button#careers-close");
	anchor.addEventListener("click", event => {
		event.preventDefault();
		document.documentElement.classList.add("bg--locked");
		dialog.show();
	});
	close.addEventListener("click", event => {
		event.preventDefault();
		document.documentElement.classList.remove("bg--locked");
		dialog.close();
	});
}
function setupCareersForm() {
	let form = document.forms.namedItem("careers");
	form.addEventListener("submit", async event => {
		event.preventDefault();
		var status = document.getElementById("careers-status");
		var controls = Array.from(form.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>("input, textarea"));
		var fetch = window.fetch(form.action, {
			body: new FormData(form),
			method: form.method,
		});
		controls.forEach(control => control.disabled = true);
		var res = await fetch;
		if (res.status >= 200 && res.status <= 299) {
			status.innerHTML = "Sent! We&rsquo;ll reach out to you if we have any opportunities.";
		} else {
			status.textContent = res.statusText;
		}
	});
}
function setupContactDialog() {
	let anchor = document.querySelector<HTMLAnchorElement>("a[href=\"#contact\"]");
	let dialog = document.querySelector<HTMLDialogElement>("dialog#contact-dialog");
	let close = document.querySelector<HTMLButtonElement>("button#contact-close");
	anchor.addEventListener("click", event => {
		event.preventDefault();
		document.documentElement.classList.add("bg--locked");
		dialog.show();
	});
	close.addEventListener("click", event => {
		event.preventDefault();
		document.documentElement.classList.remove("bg--locked");
		dialog.close();
	});
}
function setupContactForm() {
	let form = document.forms.namedItem("contact");
	form.addEventListener("submit", async event => {
		event.preventDefault();
		var status = document.getElementById("contact-status");
		var controls = Array.from(form.querySelectorAll<HTMLInputElement | HTMLTextAreaElement>("input, textarea"));
		var fetch = window.fetch(form.action, {
			body: new FormData(form),
			method: form.method,
		});
		controls.forEach(control => control.disabled = true);
		var res = await fetch;
		if (res.status >= 200 && res.status <= 299) {
			status.innerHTML = "Sent! We&rsquo;ll get back to you as soon as possible.";
		} else {
			status.textContent = res.statusText;
		}
	});
}
function setupFilters() {
	let normalize = (text: string) => text.toLowerCase().replace(/[^0-9a-z\s]+/g, "").replace(/\s+/g, " ").trim();
	document.querySelectorAll<HTMLUListElement | HTMLOListElement>("[data-filter]").forEach(list => {
		let input = document.querySelector<HTMLInputElement>(`input[data-filter-for=\"${list.dataset.filter}\"]`);
		let items = Array.from(list.querySelectorAll<HTMLLIElement>("li"));
		items.forEach(item => item.dataset.filterNormal = normalize(item.textContent));
		input.addEventListener("input", (event: InputEvent) => {
			let term = normalize(input.value);
			if (term) {
				items.forEach(item => item.dataset.filterVisible = item.dataset.filterNormal.includes(term).toString());
			} else {
				items.forEach(item => item.dataset.filterVisible = true.toString());
			}
		});
	});
}
function setupOverflow() {
	document.querySelectorAll<HTMLElement>("[data-overflow]").forEach(container => {
		let tReadMore = document.querySelector<HTMLTemplateElement>("template#template-overflow").content.cloneNode(true) as HTMLDivElement;
		container.classList.add("bg-overflow");
		container.style.setProperty("--overflow-height", container.dataset.overflow);
		container.appendChild(document.importNode<HTMLDivElement>(tReadMore, true));
		let readMore = document.querySelector(".bg-overflow-read_more.bg--inactive");
		readMore.addEventListener("click", (event) => {
			readMore.remove();
			container.classList.remove("bg-overflow");
		});
		readMore.classList.remove("bg--inactive");
	});
}
function setupPolyfills() {
	document.querySelectorAll<HTMLDialogElement>("dialog").forEach(dialog => dialogPolyfill.registerDialog(dialog));
}
function setupScrollHint() {
	document.querySelectorAll<HTMLButtonElement>("button.bg--scroll_hint").forEach(button => button.addEventListener("click", event => {
		let target = button.parentElement.nextElementSibling.getBoundingClientRect().top + window.scrollY;
		let offset = document.querySelector<HTMLElement>(".bg-site-header").getBoundingClientRect().height;
		window.scrollTo({
			behavior: "smooth",
			top: target - offset,
		});
	}));
}
function setupTabs() {
	if (document.querySelector("[data-tab]")) {
		let updateActiveTab = () => {
			document.querySelector<HTMLElement>("[data-tab=\"active\"]")?.setAttribute("data-tab", "");
			let hash = location.hash.length > 0 ? location.hash.substr(1) : "";
			console.debug(hash);
			let target = hash.length > 0 ? document.querySelector<HTMLElement>(`[data-tab]#${hash}`) : document.querySelector<HTMLElement>("[data-tab]");
			target.dataset.tab = "active";
		};
		window.addEventListener("hashchange", updateActiveTab);
		updateActiveTab();
	}
}
function setupToggles() {
	document.querySelectorAll<HTMLButtonElement>("button[data-toggle]").forEach(toggle => {
		let target = document.getElementById(toggle.dataset.toggle);
		toggle.textContent = target.classList.contains("bg--toggled") ? toggle.dataset.toggleTextOn : toggle.dataset.toggleTextOff;
		toggle.addEventListener("click", (event) => {
			toggle.textContent = target.classList.toggle("bg--toggled") ? toggle.dataset.toggleTextOn : toggle.dataset.toggleTextOff;
		});
	});
}
document.addEventListener("DOMContentLoaded", function (event) {
	setupPolyfills();
	setupAnimation();
	setupCareersDialog();
	setupContactDialog();
	setupCareersForm();
	setupContactForm();
	setupFilters();
	setupOverflow();
	setupScrollHint();
	setupTabs();
	setupToggles();
});
